export default {
  IAM_Account_Login: 'IAM_Account_Login',
  IAM_Login_Clicked: 'IAM_Login_Clicked',
  IAM_Login_MagicLink_Requested: 'IAM_Login_MagicLink_Clicked',
  IAM_Login_MagicLink_WrongDevice: 'IAM_Login_MagicLink_WrongDevice',
  IAM_Login_MagicLink_InvalidOrExpired: 'IAM_Login_MagicLink_InvalidOrExpired',
  IAM_PasswordReset_MagicLink_Requested: 'IAM_PasswordReset_MagicLink_Clicked',
  IAM_User_Successfully_Logged_In: 'IAM_User_Successfully_Logged_In',
  IAM_Facebook_Login_Clicked: 'IAM_Facebook_Login_Clicked',
  IAM_Google_Login_Clicked: 'IAM_Google_Login_Clicked',
  IAM_Account_Signup: 'IAM_Account_Signup',
  IAM_Terms_Checkbox_Clicked: 'IAM_Terms_Checkbox_Clicked',
  IAM_Terms_Link_Clicked: 'IAM_Terms_Link_Clicked',
  IAM_Signup_Clicked: 'IAM_Signup_Clicked',
  IAM_Facebook_Signup_Clicked: 'IAM_Facebook_Signup_Clicked',
  IAM_Google_Signup_Clicked: 'IAM_Google_Signup_Clicked',
  IAM_User_Successfully_Registered: 'IAM_User_Successfully_Registered',
  IAM_Login_CodeEntered: 'IAM_Login_CodeEntered',
  IAM_Consent_Displayed: 'IAM_Consent_Displayed',
  IAM_Consent_Denied: 'IAM_Consent_Denied',
  IAM_Consent_Approved: 'IAM_Consent_Approved',
};
