import { FlexGrid, LineThrough, Loader, LoadingMask, Text, Visibility } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { goTo } from '../../../lib/goTo';
import ReactGTM from '../../../lib/gtm';
import useAnalyticsContext from '../../../lib/hooks/useAnalyticsContext';
import useReturnUrl from '../../../lib/hooks/useReturnUrl';
import { SOCIAL_NETWORKS } from '../../constants';
import ANALYTICS_EVENTS from '../../constants/analyticsEvents';
import { LoginStatus } from '../../reducers/login';
import FormHeader from '../FormHeader';
import SignupForm from '../SignupForm';
import SocialAuthButtons from '../SocialAuthButtons';
import styles from './Signup.module.css';

const cx = classnames.bind(styles);

/**
 * If social signup was attempted for an already connected account then we just let them in
 * and won't set the memberName and accountId (see signup reducer and handleSignup route handler).
 * Such a case is not considered a successfully registration case.
 */
const isUserRegisteredSuccessfully = (loginStatus, accountId) => (loginStatus === LoginStatus.LoggedIn && accountId);

const getRegistrationType = (socialNetwork) => {
  let registrationType;
  switch (socialNetwork) {
    case SOCIAL_NETWORKS.FACEBOOK:
      registrationType = 'Facebook';
      break;
    case SOCIAL_NETWORKS.GOOGLE:
      registrationType = 'Google';
      break;
    default:
      registrationType = 'Email';
      break;
  }
  return registrationType;
};

const LOADER = <Loader size="sm" />;
const REDIRECT_LOADER = <Loader size="sm"><span>Registration successful! Redirecting...</span></Loader>;

export default function Signup({ loginStatus, accountId, memberName, destination, socialNetwork }) {
  const [termsChecked, setTermsChecked] = useState(false);
  const analytics = useAnalyticsContext();
  const isLoading = loginStatus === LoginStatus.InProgress;
  const isRedirecting = loginStatus === LoginStatus.LoggedIn;
  const returnUrl = useReturnUrl();

  const handleTermsChange = useCallback((newValue) => {
    setTermsChecked(newValue);
  }, []);

  useEffect(() => {
    const signupComplete = isUserRegisteredSuccessfully(loginStatus, accountId);
    if (signupComplete && typeof window !== 'undefined') {
      // As of May 2019, all new users will have a default assigned username
      analytics.identifyUser({
        id: memberName,
      });
      analytics.tagEvent({
        name: ANALYTICS_EVENTS.IAM_User_Successfully_Registered,
        attributes: {
          Username: 'Default_Assigned',
          type: getRegistrationType(socialNetwork),
        },
      });

      ReactGTM.event({
        event: 'SignupComplete',
        userID: accountId,
      });

      goTo(destination);
    }
  }, [accountId, analytics, destination, loginStatus, memberName, socialNetwork]);

  return (
    <LoadingMask
      className={cx('loadingMask')}
      isLoading={isLoading || isRedirecting}
      loader={isRedirecting ? REDIRECT_LOADER : LOADER}
    >
      <FormHeader>Get Started</FormHeader>
      <FlexGrid>
        <FlexGrid.Column mobile={12} desktop={7} className={cx('column')}>
          <SignupForm onTermsChange={handleTermsChange} />
        </FlexGrid.Column>
        <Visibility desktop={false} className={cx('fullWidth')}>
          <FlexGrid.Column mobile={12}>
            <LineThrough as="div" className={cx('divider')} uppercase>or</LineThrough>
          </FlexGrid.Column>
        </Visibility>
        <FlexGrid.Column mobile={12} desktop={5} className={cx('column', 'social')}>
          {termsChecked ? (
            <SocialAuthButtons
              magicLinkReturnUrl={returnUrl}
              returnUrl={returnUrl}
              type="signup"
            />
          ) : (
            <div className={cx('termsWarningWrapper')}>
              <Text className={cx('termsWarning')} centered>
                Signup with your Facebook or Google account requires accepting the terms and conditions.
              </Text>
            </div>
          )}
        </FlexGrid.Column>
      </FlexGrid>
    </LoadingMask>
  );
}

Signup.propTypes = {
  accountId: PropTypes.number,
  memberName: PropTypes.string,
  destination: PropTypes.string,
  loginStatus: PropTypes.number,
  socialNetwork: PropTypes.oneOf(Object.values(SOCIAL_NETWORKS)),
};

Signup.defaultProps = {
  accountId: undefined,
  memberName: undefined,
  destination: undefined,
  loginStatus: undefined,
  socialNetwork: undefined,
};
